@media (min-width: 768px) {
	.client-review-slide {
		width: 310px;
	}
}

@media (min-width: 640px) and (max-width: 768px) {
	.client-review-slide {
		width: 400px;
	}
}

@media (max-width: 640px) {
	.client-review-slide {
		width: 100%;
	}
}
