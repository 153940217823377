.user__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 10px;
  padding: 50px 30px 70px;
}
.user__card .user__image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #F2F1F5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.user__card .user__image .user-image__image {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}
.user__card .user__bio {
  margin-bottom: 27px;
}
.user__card .user__bio .user__name {
  font-weight: 800;
  font-size: 23px;
  line-height: 43px;
}
.user__card .user__bio .user__membership {
  font-size: 19px;
  font-weight: 300;
  line-height: 35px;
}
.user__card .user__courses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;
}
.user__courses .user__cousrses__card {
  padding: 12px 28px 20px;
  text-align: center;
  background-color: #F2F1F5;
}
.user__cousrses__card .courses__title {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}
.user__cousrses__card .courses__num {
  font-size: 57px;
  font-weight: 800;
}
@media(max-width: 1600px) {
  .user__courses .user__cousrses__card {
    padding: 12px 21px 20px;
    text-align: center;
    background-color: #F2F1F5;
  }
}
@media(max-width: 1199px) {
  .user__card .user__courses {
    column-gap: 20px;
  }
}