.teacher-reviews-swiper {
	/* padding-inline: 16px; */
	padding: 0 20px 60px;
}
.teacher-reviews-swiper .review-slide-item {
	width: 230px;
}

.teacher-reviews-swiper .swiper-pagination-bullet {
	background-color: gray;
}
.teacher-reviews-swiper .swiper-pagination-bullet-active {
	background-color: var(--primary) !important;
}
 .start-link {
	width: 100%;
	background-color: #ADD6E5;
	color: white;
	font-size: large;
	gap: 10px;
	font-family: "Cairo";
}

.start-link:hover {
	background-color: #ADD6E5;
}
