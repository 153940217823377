.review-teacher-item {
	padding: 10px;
	width: 230px;
}

.review-teacher-item:hover .playvide-img img {
	transform: scale(1.1);
}
.review-teacher-item > .wrapper {
	cursor: pointer;
}
/* IMG */
.review-teacher-item .playvide-img {
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	height: 250px;
	margin-bottom: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.review-teacher-item .playvide-img img {
	position: absolute;
	width: 100%;
	transition: 0.8s linear;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	object-fit: cover;
}
.review-teacher-item .playvide-img .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.522);
	z-index: 2;
}
.review-teacher-item .playvide-img .MuiAvatar-root {
	width: 50px;
	height: 50px;
	backdrop-filter: blur(5px);
	background-color: rgba(221, 221, 221, 0.752);
	position: relative;
	z-index: 3;
}
.review-teacher-item .playvide-img .MuiSvgIcon-root {
	color: var(--primary);
	font-size: 34px;
}

/* Content */
.review-teacher-item .title {
	font-weight: bold;
	flex-grow: 1;
}
.review-teacher-item .rating-review {
	flex-shrink: 0;
}
.review-teacher-item .rating-review .MuiSvgIcon-root {
	color: var(--yellow);
}
.review-teacher-item .country {
	font-family: "Cairo";
}
