.about__section {
  margin: 80px 0;
}
.about__content {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 170px;
}
.about__content .about__image,
.about__content .about__text {
  flex: 1;
}
.about__content .about__image img {
  width: 100%;
}
.about__title {
  font-size: 50px;
  font-weight: 500;
  line-height: 75.7px;
  margin-bottom: 17px;
  color: #1F1F1F;
}
.about__subtitle {
  font-size: 24px;
  line-height: 46px;
  color: #1F1F1F;
  font-weight: 300;
}
.about__title span,
.about__subtitle span {
  font-weight: 700;
}

@media(max-width: 1600px) {
  .about__content {
    column-gap: 100px;
  }
  .about__title {
    font-size: 40px;
    line-height: 66px;
  }
  .about__subtitle {
    font-size: 20px;
    line-height: 42px;
  }
}
@media(max-width: 1400px) {
  .about__title {
    font-size: 34px;
    line-height: 60px;
  }
  .about__subtitle {
    font-size: 18px;
    line-height: 38px;
  }
}
@media(max-width: 991px) {
  .about__content {
    gap: 0;
  }
}
@media(max-width: 767px) {
  .about__content {
    flex-direction: column;
  }
}
@media(max-width: 575px) {
  .about__title {
    font-size: 30px;
    line-height: 60px;
  }
}