.contact__us__section {
  margin: 135px 0px;

}
.contact__us__section .contact__us__content {
  padding: 69px 130px 35px;
}
.contact__us__section .contact__us__title {
  font-size: 40px;
  font-weight: 800;
  color: #000000;
  position: relative;
  line-height: 75px;
  margin-bottom: 40px;
}
.form__contact__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 69px;
}
.contact__form .contact__inputs {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.contact__form .contact__inputs .contact__input {
  display: flex;
}
.contact__form .contact__textarea {
  flex: 1;
}
.contact__form .contact__textarea textarea {
  all: unset;
  width: 100%;
  resize: none;
  padding: 20px 48px;
  border: 1px solid #FEE3C7;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #FFF;
  z-index: 10;
  position: relative;
  height: 100%;
}
.contact__form__submit {
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  background-color: #ADD6E5;
  color: #FFF;
  width: 530px;
  height: 88px;
  text-align: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 35px;
}

@media(max-width: 1600px) {
  .contact__form__submit {
    font-size: 30px;
    height: 80px;
  }
}
@media(max-width: 1400px) {
  .contact__us__section .contact__us__content {
    padding: 50px 80px 35px;
  }
  .form__contact__content {
    column-gap: 40px;
  }
  .contact__form__submit {
    font-size: 24px;
    height: 64px;
    margin-top: 46px;
  }
}
@media(max-width: 991px) {
  .contact__us__section .contact__us__content {
    padding: 44px 44px 30px;
  }
  .form__contact__content {
    gap: 20px;
  }
  .contact__form__submit {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .form__contact__content {
    flex-direction: column;
    row-gap: 24px;
  }
  .contact__form .contact__textarea textarea {
    display: flex;
  }
}
@media(max-width: 575px) {
  .contact__us__section .contact__us__content {
    padding: 40px 24px;
  }
}