.comment-item {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0821);
}
.comment-item .MuiAvatar-root {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0521);
	border: 2px solid rgba(209, 209, 209, 0.471);
	width: 65px;
	height: 65px;
}
.comment-item .MuiRating-root {
	font-size: 1rem;
}

.comment-item .MuiTypography-h6 {
	font-weight: bold;
}

.comment-item small {
	font-size: 0.8rem;
}

.comment-item .MuiCardContent-root {
	padding: 16px;
}
.comment-item .date {
	color: gray;
}

.comment-item .clock {
	color: gray;
	font-size: 0.8rem;
}
