@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700;900&display=swap");

.grade {
	background: #5601a5;
	background: -moz-linear-gradient(left, #5601a5 30%, #dfd2fc 120%);
	background: -webkit-linear-gradient(left, #5601a5 30%, #dfd2fc 120%);
	background: linear-gradient(to right, #5601a5 30%, #dfd2fc 120%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5601a5', endColorstr='#dfd2fc', GradientType=1);
}
html {
	scroll-behavior: smooth;
}
/*************************************************/
/* Variables */
/*************************************************/
.trans {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.specialFont {
	font-family: "Cairo", sans-serif !important;
	font-wight: bold;
}
.box-shadow {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.rounded10 {
	border-radius: 10px;
}
.rounded50 {
	border-radius: 50px;
}
.text-white {
	color: #fff !important;
}
.text-purple {
	color: #6703c5 !important;
}
.text-pink {
	color: #fa5090 !important;
}
.bg-purple {
	background: #6703c5 !important;
}
.bg-purple-shape {
	background: #e2d6fc url("https://maharttafl.com/images/bg-shape.png");
}
.bg-light-purple {
	background: #e2d6fc !important;
}
.bg-pink {
	background: #fa5090 !important;
}
.fz70 {
	font-size: 70px !important;
}
.fz50 {
	font-size: 50px !important;
}
.fz60 {
	font-size: 60px !important;
}
.fz48 {
	font-size: 24px !important;
}
.fz43 {
	font-size: 43px !important;
}
.fz40 {
	font-size: 21px !important;
}
.fz36 {
	font-size: 36px !important;
}
.fz32 {
	font-size: 32px !important;
}
.fz18 {
	font-size: 18px !important;
}
.fz21 {
	font-size: 16px !important;
}
.fz24 {
	font-size: 24px !important;
}
.fz16 {
	font-size: 16px !important;
}
.fz14 {
	font-size: 14px !important;
}
.fz12 {
	font-size: 12px !important;
}
body {
	font-family: "Cairo", sans-serif, tahoma, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.center {
	text-align: center !important;
	margin: auto !important;
}
.no-padding {
	padding: 0 !important;
}
a {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	text-decoration: none !important;
	font-family: "Cairo", sans-serif, tahoma, sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Cairo", sans-serif, tahoma, sans-serif !important;
}
/*************************************************/
/* Custom */
/*************************************************/
.modal .modal-content {
	background: none !important;
	border: none;
}
.modal .modal-body {
	background: none !important;
}
.modal .form-wrapper {
	position: relative;
}
.modal .form-wrapper button {
	position: absolute;
	right: 10px;
	top: 6px;
	background: none;
	border: none;
}
.modal .form-wrapper button i {
	color: #fa5090;
}
.modal .form-wrapper .form-control {
	height: 50px;
	border-bottom: 3px solid #fa5090;
	border-radius: 0;
	box-shadow: none;
	outline: none;
}
.modal .form-wrapper .form-control:focus {
	border-color: #fa5090;
}
.top-user-nav a {
	margin-left: 10px;
}
.top-social-icons li {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	width: 40px;
	height: 40px;
	text-align: center;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: #4b0889;
	border-radius: 50%;
}
.top-social-icons li a {
	display: inline-flex;
}
.top-social-icons li:hover {
	background: #fa5090;
}
.top-social-icons li:hover a {
	color: #fff;
}
.top-nav {
	padding: 10px 0;
	color: #fff;
	font-size: 24px;
}
.top-nav a {
	color: #fff;
	font-size: 14px;
}
.top-nav a:hover {
	color: #fa5090;
}
.top-nav ul {
	margin: 0;
}
.navbar-light {
	border: none;
}
.navbar-light .navbar-nav .nav-item.active .nav-link {
	color: white;
	background: #fa5090;
}
.navbar-light .navbar-nav .nav-item.active:hover .nav-link {
	color: white;
	background: #fa5090;
}
.navbar-light .navbar-nav .nav-item .nav-link {
	font-size: 30px;
	color: #000;
	padding: 2px 25px;
	border-radius: 40px;
	margin: 4px;
}
.navbar-light .navbar-nav .nav-item:hover .nav-link {
	color: white;
	background: #fa5090;
}
.navbar-light .dropdown a {
	background: none !important;
}
.navbar-light .dropdown a:hover {
	color: white;
	background: #fa5090 !important;
}
.navbar-light .dropdown .dropdown-menu a {
	border: none !important;
	color: #fa5090;
	font-size: 16px;
	box-shadow: none;
	border-radius: 0;
	background: none;
}
.navbar-light .dropdown .dropdown-menu a:hover {
	border: none !important;
	color: white !important;
	background: #fa5090 !important;
}
.navbar-light .navbar-nav > .open > a,
.navbar-light .navbar-nav > .open > a:focus,
.navbar-light .navbar-nav > .open > a:hover {
	color: white;
	background: #fa5090;
}
.navbar-light .navbar-nav > li > a:focus,
.navbar-light .navbar-nav > li > a:hover {
	color: white;
	background: #fa5090;
}
.fluid-col-right {
	margin-right: -100%;
	flex-basis: 100% !important;
	max-width: 100%;
}
.fluid-col-left {
	margin-left: -100%;
	flex-basis: 100% !important;
	max-width: 100%;
}
.menu-logo {
	position: absolute;
	z-index: 55;
	width: 100%;
}
.inner-page {
	background: #e2d6fc url("https://maharttafl.com/images/bg-shape.png");
}
.inner-page .menu-logo {
	position: static;
}
.hero-header {
	background: #e2d6fc;
	width: 100%;
	height: 100%;
	position: relative;
}
.hero-header .main-img {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	bottom: 0;
}
.hero-header .main-img img {
	height: calc(100% + 100px);
}
.hero-header .main-header {
	padding-top: 40%;
	padding-bottom: 20%;
}
.google-maps {
	position: relative;
	padding-bottom: 15%;
	height: 0;
	overflow: hidden;
}
.google-maps iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}
.lazy {
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.lazy.loaded {
	opacity: 1;
}
.slick-slide {
	outline: 0 !important;
	padding: 5px;
}
/*
.slick-next {
  left: 0;
}
.slick-prev {
  right: 0;
}
.slick-prev,
.slick-next {
  width: auto;
  height: 100%;
  border-radius: 0;
  color: #6703c5;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: 0 !important;
  position: absolute;
  z-index: 999;
  top: -50%;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:active,
.slick-next:active {
  color: #fff;
}
ul.slick-dots {
  display: block;
  list-style-type: none;
  text-align: center;
  margin-top: 1em;
}
ul.slick-dots li {
  display: inline-block;
}
ul.slick-dots li button {
  width: 12px;
  margin: 5px;
  height: 12px;
  border-radius: 50%;
  background: #fa5090;
  border: none;
  text-indent: -9999px;
  outline: 0;
}
ul.slick-dots li.slick-active button {
  background: #6703c5;
} */
.common-wrapper {
	padding: 4em 0;
}
.section-title {
	font-size: 30px;
	color: #000;
	font-weight: 600;
}
.section-description {
	color: #fff;
	font-size: 18px;
}
.nav-tabs {
	width: auto;
	display: inline-block;
	border: none !important;
}
.nav-tabs li {
	display: inline-block;
	background: none;
	margin: 0 !important;
}
.nav-tabs li a {
	background: none;
	border: none !important;
	color: #fa5090;
	font-size: 16px;
}
.nav-tabs li a.active {
	background: none !important;
	color: white !important;
}
.nav-tabs li:hover a {
	background: none !important;
	color: white !important;
}
.ovh {
	overflow: hidden;
}

.lSSlideWrapper {
	margin-right: 24px;
}
.lSSlideOuter .lSPager.lSGallery li.active,
.lSSlideOuter .lSPager.lSGallery li:hover {
	border-radius: 0;
}
.lSSlideOuter .lSPager.lSGallery img {
	height: 100%;
	width: 100%;
}
.btn-dark {
	background: black;
	color: white;
	font-size: 16px;
	padding: 2px 20px;
	border-radius: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-dark:hover {
	background: #fa5090;
	color: #6703c5;
	box-shadow: inset 0 0 0 2px white;
}
.btn-white {
	background: white;
	color: #fa5090;
	font-size: 14px;
	padding: 7px 20px;
	border-radius: 20px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-white:hover {
	background: none;
	color: white;
	box-shadow: inset 0 0 0 1px white;
}
.btn-white-outlined {
	box-shadow: inset 0 0 0 2px white;
	background: none;
	color: #fa5090;
	font-size: 16px;
	padding: 5px 20px;
	border-radius: 8px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-white-outlined:hover {
	background: white;
	color: #fa5090;
}
.btn-pink {
	background: #fa5090;
	color: #ffffff !important;
	font-size: 16px;
	padding: 5px 30px;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-pink:hover {
	background: none;
	color: #fa5090 !important;
	box-shadow: inset 0 0 0 1px #fa5090;
}
.btn-purple {
	background: #6703c5;
	color: #ffffff !important;
	font-size: 16px;
	padding: 5px 30px;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-purple:hover {
	background: none;
	color: #6703c5 !important;
	box-shadow: inset 0 0 0 1px #6703c5;
}
.btn-purple-outlined {
	box-shadow: inset 0 0 0 1px #6703c5;
	background: none;
	color: #6703c5 !important;
	font-size: 16px;
	padding: 5px 30px;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btn-purple-outlined:hover {
	background: #6703c5;
	color: #fff !important;
}
.categories-list {
	list-style: none;
	text-align: center;
	margin: 0;
	padding: 0;
}
.categories-list li {
	margin-bottom: 10px;
}
.categories-list li a {
	color: #fff;
	background: #6703c5;
	display: block;
	border-radius: 50px;
	padding: 10px;
	font-size: 24px;
}
.categories-list li a:hover {
	background: #33b6e7;
}
.categories-list.list-inline li {
	display: inline-block;
	width: 30%;
	margin-left: 10px;
}
.arrow-right {
	background: url("https://maharttafl.com/images/left.png") 0 0
		no-repeat;
	width: 38px;
	height: 100%;
	display: block;
	background-size: contain;
}
.arrow-left {
	background: url("https://maharttafl.com/images/right.png") 0 0
		no-repeat;
	width: 38px;
	height: 100%;
	display: block;
	background-size: contain;
}
.section-main-slider .slider-for .slick-slide {
	/*padding: 2em 0;*/
	margin-bottom: 10px;
}
.section-main-slider .slider-for .slick-slide img {
	margin: auto;
	border: 10px solid transparent;
	padding: 15px;
	border-image: url(https://maharttafl.com/images/border.png) 30 round;
	height: 400px;
	/*object-fit: contain;*/
	width: auto;
}
.section-main-slider .slider-nav {
	padding: 0 4em;
}
.section-main-slider .slider-nav .slick-slide {
	/*padding: 1em 0;*/
	overflow: hidden;
	/*border: 1px solid #707070;*/
	height: 120px;
	margin: 0 10px;
	border-radius: 20px;
	cursor: pointer;
	position: relative;
	display: flex;
}
.section-main-slider .slider-nav .slick-slide:before {
	content: "\f192";
	font-family: FontAwesome;
	position: absolute;
	top: 10px;
	left: 20px;
	font-size: 14px;
	color: #6703c5;
	opacity: 0;
}
.section-main-slider .slider-nav .slick-slide img {
	margin: auto;
	/*padding: 1em;*/
	height: 120px;
	width: 100%;
	/*object-fit: contain;*/
	border: 10px solid transparent;
	/* padding: 15px; */
	border-image: url(https://maharttafl.com/images/border.png) 30;
}
.section-main-slider .slider-nav .slick-slide.slick-current img {
	/*border: 1px solid #6703C5;*/
	border-image: url(https://maharttafl.com/images/border-hover.png) 30;
}
.section-main-slider .slider-nav .slick-slide.slick-current:before {
	opacity: 1;
}
.single-section-wrapper {
	background: #fff;
	border-radius: 100%;
	width: 190px;
	height: 190px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #000;
	margin: 15px auto;
	font-size: 16px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.single-section-wrapper a {
	color: #000;
}
.single-section-wrapper .section-icon {
	display: inline-flex;
	width: 130px;
	height: 90px;
	overflow: hidden;
}
.single-section-wrapper .section-icon img {
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}
.single-section-wrapper .section-icon .title {
	color: #000;
	font-size: 14px;
}
.single-section-wrapper:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18), inset 0 0 0 100px #fa5090;
}
.about-us {
	margin-top: 120px;
}
.img-decoration {
	position: relative;
	margin-right: 22px;
	margin-left: 22px;
}
.img-decoration:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: -20px;
	background-image: url("https://maharttafl.com/images/before.png"),
		url("https://maharttafl.com/images/before.png");
	width: 16px;
	height: 100%;
	background-position: 0 0, 0 bottom;
	background-repeat: no-repeat;
	display: block;
}
.img-decoration:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: -20px;
	background-image: url("https://maharttafl.com/images/after.png"),
		url("https://maharttafl.com/images/after.png");
	width: 16px;
	height: 100%;
	background-position: 0 0, 0 bottom;
	background-repeat: no-repeat;
	display: block;
}
.description {
	font-size: 21px;
	line-height: 35px;
}
.single-package {
	background: #fff;
	border-radius: 20px;
	padding: 18px;
	margin-bottom: 30px;
	text-align: center;
	margin: 15px 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.single-package:hover {
	box-shadow: 0 10px 16px rgba(0, 0, 0, 0.22);
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	transform: translateY(-5px);
}
.single-package .package-title {
	color: #fff;
	background: #e2d6fc;
	font-size: 20px;
	border-radius: 10px;
}
.single-package .package-icon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	text-align: center;
	margin: 20px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.single-package .package-icon img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	margin: 0 auto;
}
.single-package .package-price {
	color: #e2d6fc;
	font-size: 16px;
}
.single-package .package-price span {
	font-family: "Arial Black" !important;
}
.single-package .package-description {
	color: #393939;
	font-size: 14px;
	margin: 10px 0;
	overflow-wrap: break-word;
}
.single-package .btn {
	border-radius: 50px;
	font-size: 18px;
	padding: 2px 30px;
	color: #e2d6fc;
	box-shadow: inset 0 0 0 2px #e2d6fc;
	border: none;
}
.single-package .btn:hover {
	background: #e2d6fc;
	color: #fff;
}
.single-package.orange .package-title {
	background: #e2a114;
}
.single-package.orange .package-price {
	color: #e2a114;
}
.single-package.orange .btn {
	color: #e2a114;
	box-shadow: inset 0 0 0 2px #e2a114;
	/*margin-top:auto;*/
}
.single-package.orange .btn:hover {
	background: #e2a114;
	color: #fff;
}
.single-package.green .package-title {
	background: #60b251;
}
.single-package.green .package-price {
	color: #60b251;
}
.single-package.green .btn {
	color: #60b251;
	box-shadow: inset 0 0 0 2px #60b251;
}
.single-package.green .btn:hover {
	background: #60b251;
	color: #fff;
}
.single-package.pink .package-title {
	background: #fa5090;
}
.single-package.pink .package-price {
	color: #fa5090;
}
.single-package.pink .btn {
	color: #fa5090;
	box-shadow: inset 0 0 0 2px #fa5090;
}
.single-package.pink .btn:hover {
	background: #fa5090;
	color: #fff;
}
.single-package.purple .package-title {
	background: #6703c5;
}
.single-package.purple .package-price {
	color: #6703c5;
}
.single-package.purple .btn {
	color: #6703c5;
	box-shadow: inset 0 0 0 2px #6703c5;
}
.single-package.purple .btn:hover {
	background: #6703c5;
	color: #fff;
}
.bg-white img {
	max-width: 100% !important;
}
.single-testimonial {
	text-align: center;
	background: #f5f1ff;
	padding: 30px 20px;
	border-radius: 20px;
	margin: 0 10px;
}
.single-testimonial .avatar {
	width: 110px;
	height: 110px;
	border-radius: 100%;
	overflow: hidden;
	margin: 10px auto;
}
.single-testimonial .avatar img {
	width: 100%;
	height: auto;
	min-height: 100%;
}
.single-testimonial .name {
	font-size: 20px;
	margin-bottom: 10px;
}
.single-testimonial p {
	color: #393939;
	font-size: 16px;
}
.rate i,
.rate svg {
	color: #eeb829;
}
.form-wrapper .input-wrapper {
	position: relative;
}
.form-wrapper .input-wrapper ::placeholder {
	font-family: "Cairo", sans-serif;
	font-size: 14px;
}
.form-wrapper .input-wrapper .form-control {
	border-radius: 50px;
	border: 1px solid #b4a9be;
	min-height: 50px;
	padding: 10px 20px;
	margin: 10px 0;
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.form-wrapper .input-wrapper .form-control:focus {
	box-shadow: 0 0 0 2px #6703c5;
}
.form-wrapper .input-wrapper .icon {
	position: absolute;
	left: 5px;
	top: 4px;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background: #e2d6fc;
	color: #6703c5;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.form-wrapper .input-wrapper .icon i,
.form-wrapper .input-wrapper .icon svg {
	font-size: 15px;
}
.social-login li {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #fa5090;
}
.social-login li a {
	display: inline-flex;
}
.social-login li i,
.social-login li svg {
	color: #fff;
	font-size: 16px;
}
.social-login li:hover {
	background: #6703c5;
}
.img-radio-btns {
	margin: 1em auto;
	text-align: center;
	align-items: flex-end;
}
.form-wrapper.bg-white .img-radio-btns input[type="radio"],
.form-wrapper.bg-white input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.img-radio-btns label,
.form-wrapper.bg-white label {
	cursor: pointer;
}
.img-radio-btns input[type="radio"]:checked + label:before,
.form-wrapper.bg-white input[type="radio"]:checked + label:before,
.radio-wrapper.letters input:checked:before {
	content: "\f192";
}
.img-radio-btns input[type="radio"]:checked + label img,
.form-wrapper.bg-white input[type="radio"]:checked + label img {
	border-image: url(https://maharttafl.com/images/border-hover.png) 30 !important;
}
.radio-wrapper {
	border: 1px solid #707070;
	border-radius: 20px;
	padding: 0px;
	text-align: center;
	margin: 5px;
	transition: all 0.3s;
	width: 100%;
}
.radio-wrapper.right-wrapper,
.radio-wrapper.wrong-wrapper,
.radio-wrapper.letters {
	border: none;
	background: none !important;
}

/*.radio-wrapper.wrong-wrapper input[type=radio]:checked + label:after,*/
/*.radio-wrapper.wrong-wrapper input[type=radio]:checked:before,*/
/*li.wrong_li:after,*/
/*.radio-wrapper.letters input[data-type="wrong"]:checked + label:after {*/
/*	content: 'X';*/
/*	position: absolute;*/
/*	top: 50%;*/
/*	left: 0;*/
/*	margin: auto;*/
/*	right: 0;*/
/*	font-size: 230px;*/
/*	font-size: 100%;*/
/*	color: red;*/
/*	transform: translateY(-50%);*/
/*	text-align: center;*/
/*}*/
.radio-wrapper.wrong-wrapper input[type="radio"]:checked + label img {
	border-image: url(https://maharttafl.com/images/border.png) 30 !important;
}
.radio-wrapper img,
.radio-wrapper.letters,
.voicedefine .item img {
	height: 200px !important;
	width: 100%;
	/*object-fit: contain;*/
	border: 10px solid transparent;
	/*padding: 15px; */
	border-image: url(https://maharttafl.com/images/border.png) 30 !important;
}
.radio-wrapper:focus-within {
	border-color: #6703c5;
}
.radio-wrapper label {
	position: relative;
	display: block;
}
.radio-wrapper.right-wrapper label:before,
.radio-wrapper.letters input:before,
.exams .radio-wrapper label:before,
.radio-wrapper.wrong-wrapper label:before {
	content: "\f1db";
	font-family: FontAwesome;
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 14px;
	color: #6703c5;
	z-index: 9;
}
.radio-wrapper.letters label:before {
	display: none;
}
.radio-wrapper label:before {
	content: "\f1db";
	font-family: FontAwesome;
	position: absolute;
	top: -10px;
	left: -20px;
	font-size: 14px;
	color: #6703c5;
}
.eg-icon-img {
	background: url("https://maharttafl.com/images/eg.png") center center
		no-repeat;
	width: 150px;
	height: 150px;
	/*-webkit-background-size: contain;*/
	/*background-size: contain;*/
	margin: auto;
}
.sa-icon-img {
	background: url("https://maharttafl.com/images/sa.png") center center
		no-repeat;
	width: 150px;
	height: 150px;
	/*-webkit-background-size: contain;*/
	/*background-size: contain;*/
	margin: auto;
}
footer#Main {
	color: #fff;
	font-size: 18px;
	padding-top: 1em;
	margin-top: 50px;
	position: relative;
}
footer#Main .footer-floating-img {
	position: absolute;
	bottom: 100%;
	left: 0;
	right: 0;
	text-align: center;
	display: none;
}
footer#Main .footer-floating-img img {
	max-height: 200px;
}
footer#Main .subscribe-form {
	position: relative;
}
footer#Main .subscribe-form .form-control {
	height: 50px;
	border-radius: 50px;
	border: none;
	box-shadow: none;
	font-size: 16px;
}
footer#Main .subscribe-form button {
	border-radius: 50%;
	width: 45px;
	height: 45px;
	color: #fff;
	background: #fa5090;
	border: none;
	position: absolute;
	left: 3px;
	top: 3px;
}
footer#Main .copyrights {
	margin-top: 2em;
	padding: 15px 0;
	border-top: 1px solid white;
}
footer#Main .copyrights .cr {
	color: white;
}
footer#Main .copyrights p {
	color: white;
	margin: 0;
	line-height: 30px;
	font-size: 16px;
}
footer#Main .copyrights p span {
	color: #6703c5;
}
footer#Main .copyrights p a {
	color: white;
}
.go-top {
	position: fixed;
	bottom: 2em;
	right: 2em;
	text-decoration: none;
	background: #fa5090;
	color: white;
	font-size: 12px;
	padding: 1em 1.3em;
	display: none;
	border: 1px solid rgba(0, 0, 0, 0.32);
	border-radius: 5px;
	z-index: 999999999;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.go-top i {
	color: #fff;
}
.go-top:hover {
	background-color: rgba(0, 0, 0, 0.6);
}
.pagination .page-link {
	background: none;
	border: 1px solid #fa5090;
	color: #6703c5;
	border-radius: 0 !important;
	margin: 4px;
}
.pagination .page-link:hover {
	background: #6703c5;
	color: #fff;
}
.inner-tiny-list {
	display: flex;
	justify-content: center;
	align-items: center;
}
.inner-tiny-list a:not(.dms-toggler) {
	color: #fff;
	background: #fa5090;
	padding: 5px 20px;
	font-size: 23px;
	border-radius: 20px;
}
@media screen and (max-width: 767px) {
	.droopmenu-navbar {
		box-shadow: none;
	}
	.droopmenu-header {
		background: #6703c5;
		padding: 2px !important;
		height: auto;
		width: auto;
		border-radius: 20px;
		overflow: hidden;
		text-align: center;
	}
	.droopmenu-header .droopmenu-toggle {
		padding: 0;
	}
	.droopmenu-navbar {
		background: none;
	}
}
.dms-toggler {
	position: static;
	background: #6703c5;
	color: #fff !important;
	padding: 10px;
	width: auto;
	height: auto;
	border-radius: 50%;
	margin: 0 5px !important;
	text-align: center;
	float: none;
}
.dms-toggler i,
.dms-toggler svg {
	color: #fff;
	font-size: 23px;
}
.dms-toggler:hover {
	background: #fa5090;
}
.tree_widget-sec > ul {
	float: right;
	width: 100%;
	margin: 0;
}

.tree_widget-sec > ul > li > a {
	float: left;
	width: 100%;
	font-family: Open Sans;
	font-size: 13px;
	color: #888888;
	line-height: 41px;
}

.tree_widget-sec > ul > li > a i {
	float: left;
	font-size: 28px;
	line-height: 41px;
	color: #babebe;
	margin-right: 5px;
	width: 35px;
}

.pf-field > input,
.pf-field > textarea {
	float: right;
	width: 100%;
	border: 2px solid #e8ecec;
	margin-bottom: 20px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
	padding: 14px 45px 14px 15px;
	background: #ffffff;
	font-family: Open Sans;
	font-size: 13px;
	color: #101010;
	line-height: 24px;
}

.pf-title {
	float: right;
	width: 100%;
	margin-top: 30px;
	font-family: Open Sans;
	font-size: 13px;
	color: #202020;
	margin-bottom: 13px;
}

.student-profile {
	background: #e2d6fc;
	padding: 35px 0px;
}

.page-caption h2 {
	color: #5601a5;
	margin-bottom: 30px;
	font-size: 45px;
}

.list-profile li {
	border-bottom: 1px solid #6703c5;
	padding: 10px;
}
.list-profile li a {
	color: #6703c5;
	font-size: 16px;
}

.list-profile li:nth-last-child {
	border-bottom: 1px solid transparent;
}
/*.navbar-light .navbar-nav .nav-item .nav-link {*/
/*    font-size: 30px;*/
/*    color: #000;*/
/*    padding: 2px 16px;*/
/*    border-radius: 40px;*/
/*    margin: 4px;*/
/*}*/

.btn-pay {
	position: relative;
}

.payment-btn {
	position: absolute;
	top: 50px;
	background: #fff;
	padding: 5px 10px 18px;
	width: calc(100% + 36px);
	display: none;
	right: -18px;
	left: 0;
	border-radius: 0 0 20px 20px;
	z-index: 1;
}
.payment-btn a + a {
	margin-top: 10px;
}

.shown {
	display: block;
	transition: all 0.5s ease-in-out;
}

.main-color {
	color: #5601a5;
}

.bank-container {
	margin: 10px 0px 35px 0px;
}

.diff-arrows {
	/*position: relative;*/
	/*margin: -30% 0 30% 0;*/
}

.diff-arrows img {
	max-width: 60px !important;
}

.diff-arrows .next {
	position: absolute;
	left: -70px;
	top: 50%;
	transform: translateY(-50%);
}

.diff-arrows .prev {
	position: absolute;
	right: -70px;
	top: 50%;
	transform: translateY(-50%);
}

.click-here {
	/* width: 100px; */
	height: auto;
	margin: 0 auto;
	display: block;
	margin-top: 100px;
	color: #fff !important;
	border-radius: 13px;
	font-size: 23px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
	font-size: 27px;
	color: #000;
	padding: 2px 6px;
	border-radius: 40px;
	margin: 2px;
}

.main-letter {
	margin: 0 auto;
	display: block;
	text-align: center;
	font-size: 118px;
	font-weight: bold;
	color: red;
	font-family: arial !important;
}

.letters label {
	font-size: 40px;
	/*display: block;*/
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: arial;
}

.navbar-light .navbar-nav .nav-item .nav-link {
	font-size: 16px;
	color: #000;
	padding: 2px 5px;
	border-radius: 40px;
	margin: 2px;
}

section.section-slider.my-5 {
	margin-top: 1em !important;
}

.img-fluid {
	max-width: 100% !important;
	height: auto;
}
.form-wrapper.bg-white.rounded10.p-4.shadow #right-image,
.voicedefine #main-image {
	height: 300px;
	margin: 0 auto;
	text-align: center;
	border: 10px solid transparent;
	padding: 0;
	border-image: url(https://maharttafl.com/images/border.png) 30 round;
	/*margin-right: 50%;*/
	/*transform: translateX(50%);*/
	/*object-fit: contain;*/
}

#right-image[src=""] {
	display: none;
}

#refresh img {
	max-width: 45px !important;
	cursor: pointer;
}

img#answr-image {
	margin: auto;
	border: 10px solid transparent;
	/*padding: 15px;*/
	border-image: url(https://maharttafl.com/images/border.png) 15 round;
}

.choosing-list {
	display: flex;
	padding: 0;

	width: 80%;
	margin: 0px auto 50px;
	z-index: 9;
	position: relative;
}
.choosing-list li {
	position: relative;
	border: 10px solid transparent;
	/* padding: 15px; */
	border-image: url(https://maharttafl.com/images/border.png) 30 !important;
}
.choosing-list img {
	border: 10px solid transparent;
	/* padding: 15px; */
	border-image: url(https://maharttafl.com/images/border.png) 30 !important;
}
.blog-image img {
	height: 200px;
	width: 100%;
	object-fit: cover;
	border-radius: 10px 10px 0 0;
}

.blog-data a {
	color: #393939;
	font-size: 14px;
	padding: 10px;
	overflow-wrap: break-word;
	display: block;
}

.blog-page-content-wrapper {
	font-size: 14px;
	line-height: 34px;
}

.floated-image ul {
	display: flex;
	flex-wrap: wrap;
}

.floated-image li {
	margin: 6px 7px !important;
	border: 10px solid transparent;
	/* padding: 15px; */
	border-image: url(https://maharttafl.com/images/border.png) 30;
}

.floated-image ul li img {
	height: 180px;
	object-fit: contain;
	background: white;
	width: 180px;
}

.itemz {
	text-align: center;
}

.itemz img {
	margin: auto;
	border: 10px solid transparent;
	border-image: url(https://maharttafl.com/images/border.png) 30 round;
	height: 300px !important;
	width: 100%;
}

.video-item {
	margin: auto;
	border: 10px solid transparent;
	padding: 15px;
	border-image: url(https://maharttafl.com/images/border.png) 12 round;
	width: 70%;
}
video.myVideo {
	display: block;
}

.menu-wrapper.py-3 {
	padding: 5px 0 !important;
}
section.user-form.my-5 {
	margin-top: 15px !important;
}

.custom-sizing .radio-wrapper {
	padding: 0;
}

.custom-sizing .radio-wrapper img {
	height: 300px !important;
}

.logo.mb-2 {
	margin-bottom: 0 !important;
}

.subscribe img {
	width: 180px;
	margin: 25px auto 0;
}

.form-wrapper.bg-white.rounded10.p-4.shadow {
	position: relative;
}
.tasnifadvance .main-image {
	border-image-source: linear-gradient(to left, #743ad5, #d53a9d) !important;
	border: 10px solid;
	border-image-slice: 1;
	border-width: 5px;
	margin-bottom: 20px;
	width: 500px;
	height: 300px;
}

.tasnifadvance .itemz {
	width: 100%;
	height: 190px;
}

.tasnifadvance .itemz img {
	width: 100%;
	height: 100% !important;
}

.things .radio-wrapper img {
	height: 260px !important;
}

a#refresh-page {
	cursor: pointer;
	margin: auto;
	text-align: center;
	display: block;
}
.two-col-test .radio-wrapper img {
	height: 300px !important;
}
.prepostest .main-image img,
.fh300 {
	height: 300px !important;
}
.fh400,
.radio-wrapper img.fh400 {
	height: 400px !important;
}
section.user-form #right-image {
	width: 100% !important;
}

.radio-wrapper.letters {
	width: 200px;
}
.right-image-box {
	max-width: 100%;
}
#my-video-container {
	margin-top: 20px;
}
/*************************************************/
/* Responsive */
/*************************************************/
@media all and (max-width: 1024px) {
	.hero-header .main-img img {
		height: auto;
	}
	.section-title {
		font-size: 30px;
	}
}
@media all and (max-width: 768px) {
	.hero-header .main-img img {
		display: none;
	}
	.menu-logo {
		position: static;
	}
	.hero-header .main-header {
		padding: 1em 0;
	}
	.about-us {
		margin-top: 0;
	}
	footer#Main {
		margin-top: 20%;
	}
	.fluid-col-right,
	.fluid-col-left {
		margin-right: 0;
		margin-left: 0;
		flex-basis: auto;
		max-width: initial;
	}
	.navbar-light {
		background: #fa5090;
		width: 100%;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.navbar-light .navbar-nav .nav-item .nav-link {
		color: #fff !important;
		padding: 10px 0;
	}
}
@media all and (max-width: 600px) {
	.whatbelong .img-radio-btns {
		flex-wrap: wrap;
	}
	.whatbelong .img-radio-btns .radio-wrapper {
		flex-basis: 100px;
	}
	.tasnifadvance .main-image {
		width: 100%;
	}
	.things .radio-wrapper img {
		height: 180px !important;
	}
	.top-nav ul {
		display: inline-block;
	}
	.floated-image ul {
		padding-right: 0;
	}
	.floated-image ul li img {
		object-fit: fill;
		width: 130px;
		height: 130px;
	}
	.video-item {
		width: 100%;
		padding: 0;
	}
	#my-video-container {
		height: 200px;
	}
	.ekhtarkelma .radio-wrapper.letters {
		flex-basis: 46%;
	}
	.letters label {
		font-size: 34px;
	}
	.radio-wrapper.letters {
		width: 100%;
	}
	.two-col-test .radio-wrapper img {
		height: 200px !important;
	}

	.custom-sizing .radio-wrapper img {
		height: 150px !important;
	}
	.radio-wrapper img,
	.radio-wrapper.letters,
	.voicedefine .item img {
		height: 150px !important;
	}
	.mobile-240h {
		height: 240px !important;
	}
	.diff-arrows .next {
		left: 0 !important;
		top: 100% !important;
		transform: translateY(0) !important;
	}
	.diff-arrows .prev {
		right: 0 !important;
		top: 100% !important;
		transform: translateY(0) !important;
	}
	.section-main-slider .slider-for .slick-slide img {
		height: 330px;
	}
	.section-main-slider .slider-nav {
		padding: 0 3em;
	}
	.slick-prev,
	.slick-next {
		top: 40%;
	}
	.radio-wrapper label.eg-icon-img {
		width: 80px;
		height: 80px;
	}
	.top-nav {
		text-align: center;
	}
	.lSSlideWrapper {
		margin-right: 0;
	}
	footer#Main {
		text-align: center;
	}
	footer#Main .footer-floating-img {
		bottom: 95%;
	}
	.fz48 {
		font-size: 28px !important;
	}
	.w-50 {
		width: 100% !important;
	}
	.wow {
		animation: none;
		visibility: visible !important;
	}
}
