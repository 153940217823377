.single-price {
	padding: 20px 16px;
	height: 100%;
}

.single-price .title-wrap {
	text-align: center;
}
.single-price .title-wrap .MuiAvatar-root {
	padding: 5px;
	height: 70px;
	width: 70px;
	border-radius: 10px;
	margin-inline: auto;
}

.single-price .title-wrap .price-title {
	font-weight: bold;
	margin-top: 10px;
	font-size: 22px;
}

.single-price .title-wrap p {
	font-size: 14px;
	color: gray;
	margin-bottom: 20px;
}

.single-price .price-plans {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(3, minmax(50px, auto));
	align-items: center;
	text-align: center;
}

.single-price .price-plans .child {
	padding-block: 5px;
	border-radius: 5px;
}
.single-price .price-plans .child.icon {
	background-color: #d9d9d934;
	display: flex;
	justify-content: center;
	padding-block: 10px;
}
.single-price .price-plans .child.icon .img {
	width: 30px;
	height: 20px;
}
.single-price .price-plans .child.label {
	background-color: var(--primary);
	color: white;
}

.single-price .price-plans .child.label,
.single-price .price-plans .child.value {
	font-size: 15px;
}

.single-price .suscribe-button {
	display: block;
	max-width: 150px;
	width: 100%;
	color: white;
	margin-top: 20px;
	margin-bottom: 4px;
	margin-inline: auto;
	font-size: 16px;
	font-family: "Cairo";
	border-radius: 10px;
}

.single-price .suscribe-button:hover {
	background-color: #ADD6E5;
}

.single-price .subtext {
	font-size: 12px;
	color: gray;
}
