.games__section {
  width: 90%;
  height: 80vh;
  background-color: #fff;
  margin: 40px auto !important;
  max-width: 95%;
}
.games__section img {
  object-fit: contain;
  width: inherit;
  height: inherit;
}
.games__categories {
  /* overflow: hidden; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* padding: 1px; */
  margin-bottom: 10px;
  gap: 10px;
}
 .games__button {
  background-color: #ADD6E5;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  border-radius: 8px;
  /* width: 50%; */
}
@media (max-width: 767px) {
  .games__categories {
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    flex-wrap: wrap;
  }
  .games__categories button {
    flex-basis: calc(100% / 4);
    flex-grow: 1;
  }
}

@media (max-width: 480px) {
  .games__categories button {
    flex-basis: 100%;
  }
}
