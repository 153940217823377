.gameButton {
  border: 4px solid #dedede !important;
  border-radius: 15px !important;
  padding: 50px !important;
  font-size: x-large !important;
  font-weight: bolder !important;
  width: 100% !important;
}
.gameButton:disabled {
  background-color: blueviolet !important;
  color: white !important;
}
