.speech__experts .speech__title {
  font-size: 40px;
  font-weight: 800;
  color: #000000;
  line-height: 74.96px;
}
.speech__experts .speech__subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 29.98px;
  margin-bottom: 100px;
}
.speech__experts .speech__subtitle span {
  font-weight: 800;
}
.speech__experts .speech__content {
  padding: 70px 60px 50px;
}
.speech__content .monitor__content {
  display: flex;
  justify-content: space-between;
  column-gap: 80px;
}
.monitor__content .rigth__col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-120px);
}
.monitor__content .rigth__col .monitor__image {
  width: 217px;
  height: 217px;
  border-radius: 90px;
  background-color: #FB7488;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  border: 1px solid #ADD6E5;
}
.monitor__content .rigth__col .monitor__image img {
  width: 150px;
}
.monitor__content .rigth__col .monitor__name {
  font-size: 35px;
  font-weight: 800;
  line-height: 65.59px;
  color: #000000;
}
.monitor__content .rigth__col .monitor__job {
  font-weight: 300;
  font-size: 22px;
}
.monitor__content .left__col {
  flex: 1;
}
.monitor__content .list__details {
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.monitor__content .list__details li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  font-size: 25px;
  font-weight: 500;
  color: #1F1F1F;
}
.monitor__content .list__details li .square__icon {
  font-size: 10px;
}
.booking__form {
  width: 100%;
  background-color: #F2F1F5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 18px;
  padding: 19px 70px 35px;
}
.booking__form .booking__title {
  font-weight: 800;
  font-size: 30px;
  color: #000000;
  line-height: 56.22px;
}
.booking__form .booking__content {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}

.booking__content .center__col,
.booking__content .left__col,
.booking__content .rigth__col {
  text-align: center;
}

.booking__content .box__title {
  font-size: 25px;
  font-weight: 500;
  color: #1F1F1F;
  line-height: 46.85px;
  margin-bottom: 8px;
}
.booking__content input[type='radio'] {
  display: none;
}
.booking__content .radio__inputs__container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}
.booking__content .radio__inputs__container label {
  cursor: pointer;
  opacity: .6;
}
.booking__content input[type='radio']:checked + label {
  opacity: 1;
}
.radio__input__num-of-course label {
  background-color: red;
  color: #FFF;
  width: 50px;
  height: 50px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
.booking__form + .speech__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 47px;
  margin-top: 40px;
}
.booking__form + .speech__footer .total__price .total {
  font-size: 25px;
  font-weight: 500;
  color: #000000;
  line-height: 46.85px;
}
.booking__form + .speech__footer .total__price .price {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}
.booking__form + .speech__footer button {
  all: unset;
  font-size: 24px;
  font-weight: 700;
  color: #FFF;
  background-color: #DEB50F;
  border-radius: 10px;
  text-align: center;
  width: 160px;
  height: 57px;
}
@media(max-width: 1400px) {
  .monitor__content .rigth__col .monitor__image {
    width: 180px;
    height: 180px;
    border-radius: 67px;
  }
  .monitor__content .rigth__col .monitor__name {
    font-size: 30px;
    line-height: 58.59px;
  }
  .monitor__content .rigth__col .monitor__job {
    font-weight: 300;
    font-size: 20px;
  }
  .monitor__content .list__details li {
    font-size: 22px;
  }
}
@media(max-width: 1199px) {
  .booking__form {

  }
  .booking__form .booking__content {
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  .booking__content .left__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
}
@media(max-width: 991px) {
  .booking__form {
    padding: 20px;
  }
}
@media(max-width: 767px) {
  .speech__content .monitor__content {
    flex-direction: column;
  }
  .monitor__content .list__details {
    align-items: center;
    transform: translateY(-120px);
  }
  .booking__form .booking__content {
    justify-content: center;
    gap: 40px;
  }
  .speech__experts .speech__content {
    padding: 70px 18px 50px;
  }
}
@media(max-width: 575px) {
  .booking__form + .speech__footer {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
  }
  .total__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}

.login__container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000a6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .3s ease-in-out;
  opacity: 0;
  animation: show .3s ease-in-out forwards;
}
@keyframes show {
  to {
    opacity: 1;
  }
}
.login__container .card {
  animation: popup .3s ease-in-out forwards;
}
@keyframes popup {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.login__popup {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 64px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}
.login__popup .rigth {
  flex: 1;
}
.login__popup .left {
  flex: 1;
}
.login__popup .login__image img {
  width: 100%;
  height: 100%;
 object-fit: cover;
}
.login__popup .login__form {
  display: flex;
  flex-direction: column;
  row-gap: 35px;
}
.login__popup .login__btns {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  align-items: flex-start;
}
.login__popup .login__btns .submit__form {
  width: 100%;
  color: #FFF;
  background-color: #ADD6E5;
  font-size: 35px;
  font-weight: 400;
  height: 88px;
  border-radius: 8px;
  margin-bottom: 4px;
}
.login__popup .login__btns .forget__password {
  font-weight: 700;
  font-size: 20px;
  color: #DEB50F;
}
.login__form .switch__signin__signup {
  font-weight: 700;
  font-size: 31px;
  color: #DEB50F;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login__popup__title {
  font-size: 62px;
  font-weight: 400;
  color: #1F1F1F;
  line-height: 116.19px;
  position: relative;
  width: fit-content;
}
.login__popup__title .tag {
  position: absolute;
  top: -40px;
  left: -60px;
}
.login__popup__subtitle {
  font-size: 29px;
  font-weight: 400;
  color: #1F1F1F;
  line-height: 54.35px;
  margin-bottom: 35px;
}
.login__form__inputs__container {
  display: flex;
  position: relative;
  width: 100%;
}