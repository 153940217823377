:root {
	--primary: #ADD6E5;
	--yellow: #ffd046;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
button {
	border: none;
	outline: none;
	background-color: transparent;
	cursor: pointer !important;
	font-family: "Cairo", sans-serif;
}

a {
	all: unset;
	font-family: "Cairo", sans-serif;
}
body {
	background-color: #f2f1f5;
	direction: rtl;
	font-family: "Cairo", sans-serif;
}
.title__line,
.title__tag {
	position: relative;
}
.title__tag .tag {
	position: absolute;
	top: -50px;
	left: -55px;
}
.line {
	position: absolute;
	bottom: -10px;
	left: 0;
	width: 100%;
}
.contact__textarea,
.contact__input,
.login__form__inputs__container,
.select__box__controle,
.input__controle {
	position: relative;
}
.contact__textarea::after,
.contact__input::after,
.login__form__inputs__container::after,
.select__box__controle::after,
.input__controle::after {
	content: "";
	position: absolute;
	top: -1px;
	left: -1px;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
	border-radius: 8px;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(254, 227, 199, 1) 100%
	);
}
.contact__textarea::after,
.contact__input::after,
.login__form__inputs__container::after {
	background: rgb(68, 129, 235);
	background: linear-gradient(
		90deg,
		rgba(68, 129, 235, 1) 0%,
		rgba(254, 227, 199, 1) 100%
	);
}
.container {
	width: 1466px;
	margin: 0 auto;
}
/* .program__icon {
  background: linear-gradient(to right, #ff8a65 7%, #ff5722 89%, #ff5722 100%);
  box-shadow: 0 20px 30px #ff7043;
  border-radius: 50px;
} */

@media (max-width: 1600px) {
	.container {
		width: 1200px;
	}
}
@media (max-width: 1400px) {
	.container {
		width: 1150px;
	}
}
@media (max-width: 1199px) {
	.container {
		width: 900px;
	}
}
@media (max-width: 991px) {
	.container {
		width: 100%;
		padding: 0 40px;
	}
}
@media (max-width: 767px) {
	.container {
		width: 100%;
		padding: 0 24px;
	}
}
@media (max-width: 575px) {
	.container {
		width: 100%;
		padding: 0 12px;
	}
	.title__tag .tag {
		width: 80px;
		top: -47px;
		left: -47px;
	}
}
