.teacher-review-item-modal {
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -35%);
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
}

.teacher-review-item-modal .close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	background-color: rgba(237, 237, 237, 0.456);
	backdrop-filter: blur(10px);
	color: var(--primary);
}
.teacher-review-item-modal .MuiPaper-root {
	padding: 50px;
}

.teacher-review-item-modal .MuiCardHeader-root,
.teacher-review-item-modal .MuiCardContent-root {
	padding: 0 !important;
}

.teacher-review-item-modal .header {
	gap: 16px;
	align-items: center;
}

.teacher-review-item-modal .header .MuiCardHeader-avatar {
	margin: 0;
}

.teacher-review-item-modal .header .MuiCardHeader-avatar .MuiAvatar-root {
	width: 55px;
	height: 55px;
}

.teacher-review-item-modal .header .MuiTypography-h6 {
	font-weight: bold;
}
.teacher-review-item-modal .header .MuiTypography-body2 {
	font-family: "Cairo";
}

.teacher-review-item-modal .header .MuiCardHeader-action {
	align-self: center;
}

.teacher-review-item-modal .card-body .description {
	font-family: "Cairo";
	margin-block: 20px;
	margin-inline-start: 5px;
}

.teacher-review-item-modal .start-link {
	width: 100%;
	background-color: var(--primary);
	color: white;
	font-size: large;
	gap: 10px;
	font-family: "Cairo";
}

.teacher-review-item-modal .start-link:hover {
	background-color: var(--primary);
}

@media (min-width: 992px) {
	.teacher-review-item-modal {
		width: 48%;
	}
}
@media (min-width: 768px) and (max-width: 992px) {
	.teacher-review-item-modal {
		width: 60%;
	}
}
@media (min-width: 640px) and (max-width: 768px) {
	.teacher-review-item-modal {
		width: 70%;
	}
}

@media (max-width: 640px) {
	.teacher-review-item-modal {
		width: 95%;
	}

	.teacher-review-item-modal .MuiPaper-root {
		padding: 35px;
	}
}
