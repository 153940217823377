.program__details__btn {
  width: 50% !important;
  padding: 50px;
  text-align: center !important;
}
.program__icon img {
  width: 150px !important;
  height: 150px !important;
  padding: 15px;
  background-color: transparent !important;
  /* object-fit: contain !important; */
}
.program__details__btn {
  cursor: pointer !important;
}
.prices__section {
  margin-bottom: 163px !important;
}
.prices__title {
  font-size: 40px !important;
  font-weight: 800 !important;
  color: #000000 !important;
  margin-bottom: 67px !important;
  line-height: 74.96px !important;
  text-align: center !important;
  position: relative !important;
}
.prices__content {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 35px !important;
}
.prices__content {
  grid-template-columns: 1fr !important;
}
.single__program__box {
  padding: 42px 20px 25px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
.single__program__box .program__icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 84px !important;
  height: 84px !important;
  border-radius: 10px !important;
}
.card:nth-child(1) .single__program__box .program__icon {
  background-color: #ADD6E5 !important;
}
.card:nth-child(2) .single__program__box .program__icon {
  background-color: #aa4db9 !important;
}
.card:nth-child(3) .single__program__box .program__icon {
  background-color: #f05165 !important;
}
.card:nth-child(4) .single__program__box .program__icon {
  background-color: #deb50f !important;
}
.single__program__box .programe__title {
  font-size: 23px !important;
  font-weight: 800 !important;
  color: #000000 !important;
  line-height: 43.1px !important;
  margin-bottom: 5px !important;
  margin-top: 16px !important;
}
.single__program__box .program__subtitle {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  line-height: 33.73px !important;
  text-align: center !important;
  margin-bottom: 24px !important;
}
.single__program__box .program__details__btn {
  margin-top: 3px !important;
  padding: 16px !important;
  border-radius: 15px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #fff !important;
  width: 50% !important;
}
.card:nth-child(1) .single__program__box .program__details__btn {
  background-color: #ADD6E5 !important;
}
.card:nth-child(2) .single__program__box .program__details__btn {
  background-color: #aa4db9 !important;
}
.card:nth-child(3) .single__program__box .program__details__btn {
  background-color: #f05165 !important;
}
.card:nth-child(4) .single__program__box .program__details__btn {
  background-color: #deb50f !important;
}
@media (max-width: 1600px) {
  .single__program__box .programe__title {
    font-size: 20px !important;
  }
  .single__program__box .program__subtitle {
    font-size: 16px !important;
  }
}
@media (max-width: 1400px) {
  .single__program__box {
    padding: 42px 18px 25px !important;
  }
  .single__program__box .program__details__btn {
    padding: 10px !important;
  }
  .single__program__box .program__icon {
    width: 60px !important;
    height: 60px !important;
  }
  .single__program__box .program__icon svg {
    height: 26px !important;
  }
}
@media (max-width: 1199px) {
  .single__program__box .program__subtitle {
    min-height: 80px !important;
    display: flex !important;
    align-items: center !important;
  }
}
