.speech__experts .speech__title {
	font-size: 40px;
	font-weight: 800;
	color: #000000;
	line-height: 74.96px;
}
.speech__experts .speech__subtitle {
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	line-height: 29.98px;
	margin-bottom: 100px;
}
.speech__experts .speech__subtitle span {
	font-weight: 800;
}
.speech__experts .speech__content {
	padding: 70px 60px 50px;
}
.speech__content .monitor__content {
	display: flex;
	justify-content: space-between;
	column-gap: 80px;
}
.monitor__content .rigth__col {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transform: translateY(-120px);
}
.monitor__content .rigth__col .monitor__image {
	width: 217px;
	height: 217px;
	border-radius: 90px;
	background-color: #fb7488;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 11px;
	border: 1px solid #ADD6E5;
}
.monitor__content .rigth__col .monitor__image img {
	width: 150px;
}
.monitor__content .rigth__col .monitor__name {
	font-size: 35px;
	font-weight: 800;
	line-height: 65.59px;
	color: #000000;
}
.monitor__content .rigth__col .monitor__job {
	font-weight: 300;
	font-size: 22px;
}
.monitor__content .left__col {
	flex: 1;
}
.monitor__content .list__details {
	list-style: none;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin-top: 10px;
}
.monitor__content .list__details li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 10px;
	font-size: 25px;
	font-weight: 500;
	color: #1f1f1f;
}
.monitor__content .list__details li .square__icon {
	font-size: 10px;
}
.booking__form {
	width: 100%;
	background-color: #f2f1f5;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	row-gap: 18px;
	padding: 19px 70px 35px;
}
.booking__form .booking__title {
	font-weight: 800;
	font-size: 30px;
	color: #000000;
	line-height: 56.22px;
}
.booking__form .booking__content {
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	width: 100%;
}

.booking__content .center__col,
.booking__content .left__col,
.booking__content .rigth__col {
	text-align: center;
}

.booking__content .box__title,
.booking__form .payment-box .box__title {
	font-size: 25px;
	font-weight: 500;
	color: #1f1f1f;
	line-height: 46.85px;
	margin-bottom: 8px;
}
.booking__content input[type="radio"] {
	display: none;
}
.booking__content .radio__inputs__container {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 40px;
}
.booking__content .radio__inputs__container label {
	cursor: pointer;
	opacity: 0.6;
}
.booking__content input[type="radio"]:checked + label {
	opacity: 1;
}
.radio__input__num-of-course label {
	background-color: red !important;
	color: #fff !important;
	width: 110px !important;
	height: 50px !important;
	font-size: 10px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	font-weight: 900 !important;
}
.booking__form + .speech__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 47px;
	margin-top: 40px;
}
.booking__form + .speech__footer .total__price .total {
	font-size: 25px;
	font-weight: 500;
	color: #000000;
	line-height: 46.85px;
}
.booking__form + .speech__footer .total__price .price {
	font-size: 30px;
	font-weight: 700;
	color: #000000;
}
.booking__form + .speech__footer button {
	all: unset;
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	background-color: #deb50f;
	border-radius: 10px;
	text-align: center;
	width: 160px;
	height: 57px;
}

.booking__form .payment-box {
	text-align: center;
	/* width: 100%; */
}

.booking__form .payment-wrap .paymentmethod {
	background-color: white;
	padding: 15px 10px;
	border-radius: 10px;
	transition: 0.2s;
	opacity: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
}
.booking__form .payment-wrap .paymentmethod.selected {
	border: 1px solid #ADD6E5;
	opacity: 1;
}
.booking__form .payment-wrap .paymentmethod img {
	width: 80px;
	height: 30px;
}
.intl-tel-input.flag-container.selected-flag{
position: absolute !important;
left:0 !important;
}
@media (max-width: 1400px) {
	.monitor__content .rigth__col .monitor__image {
		width: 180px;
		height: 180px;
		border-radius: 67px;
	}
	.monitor__content .rigth__col .monitor__name {
		font-size: 30px;
		line-height: 58.59px;
	}
	.monitor__content .rigth__col .monitor__job {
		font-weight: 300;
		font-size: 20px;
	}
	.monitor__content .list__details li {
		font-size: 22px;
	}
}
@media (max-width: 1199px) {
	.booking__form .booking__content {
		flex-wrap: wrap;
		align-items: center;
		gap: 20px;
	}
	.booking__content .left__col {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 20px;
	}
}
@media (max-width: 1024px) {
	.booking__content {
		justify-content: center;
	}
}
@media (max-width: 991px) {
	.booking__form {
		padding: 20px;
	}
}
@media (max-width: 767px) {
	.speech__content .monitor__content {
		flex-direction: column;
	}
	.monitor__content .list__details {
		align-items: center;
		transform: translateY(-120px);
	}
	.booking__form .booking__content {
		justify-content: center;
		gap: 40px;
	}
	.speech__experts .speech__content {
		padding: 70px 18px 50px;
	}
}
@media (max-width: 575px) {
	.booking__form + .speech__footer {
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 30px;
	}
	.total__price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
	}
}
