.expert-card {
	text-align: center;
}

.expert-card .info-wrap {
	text-align: start;
}
.expert-card .MuiSvgIcon-root {
	font-size: 1.2rem;
	color: var(--yellow);
}
.expert-card .MuiAvatar-root {
	width: 90px;
	height: 90px;
	margin-inline: auto;
	margin-bottom: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0821);
}

.expert-card .MuiTypography-h6 {
	font-weight: bold;
}

.expert-card .rating {
	font-size: 0.9rem;
}

.expert-card .gobtn {
	background-color: var(--primary);
	font-family: "Cairo";
	width: 100%;
}
