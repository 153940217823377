.prices__section {
  margin-bottom: 163px;
}
.prices__title {
  font-size: 40px;
  font-weight: 800;
  color: #000000;
  margin-bottom: 67px;
  line-height: 74.96px;
  text-align: center;
  position: relative;
}
.prices__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
}
@media(max-width: 991px) {
  .prices__content {
    grid-template-columns: 1fr;
  }
}




.payCard {
	border-radius: 20px;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
	padding: 10px;
	height: 660px;
}

.payCardIcon {
	display: inline-flex;
	border-radius: 10px;
	background-color: #ADD6E5;
	padding: 20px;
	color: white;
}

.payCardIcon.payCardIconSecond {
	background-color: #AA4DB9;
}

.payCardTitle {
	font-weight: bolder;
}

.payCardRightFlag {
	background: rgba(217, 217, 217, 0.2);
	;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.payCardLeftFlag {
	background: rgba(217, 217, 217, 0.2);
	;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.payCardRightFlag img,
.payCardLeftFlag img {
	width: 80px;
	display: inline-flex
}



.payCardMonth {
	background: #ADD6E5;
	color: white;
	border-radius: 10px;
	font-weight: bolder;
	padding: 15px;
	display: inline-block;
	width: 100%;
	font-weight: bolder;
}

.payCardPrice {
	width: 100%;

	/* border-collapse: separate !important; */
}

.payCardButton {
	background-color: #DEB50F;
	color: #ffffff !important;
	padding: 15px 40px;
	border-radius: 10px;
	font-weight: 700;
}

.payCardSmallNote {
	color: #807575b5;
	font-weight: bolder;
}

.form-check-input {
	appearance: none;
	cursor: pointer;
}



.modal-content.filledModalContent {
	background-color: white !important;
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
}


.modal.modal-body.filledModalBody {
	background-color: #fff !important;
}

.selectedTR {
	border: 4px solid #ADD6E5;
	box-shadow: 0px 0px 12px 0px #ADD6E5;
	padding: 5px;
}

.paymentImage {
	box-shadow: 0px 0px 12px 0px grey;

	height: 150px;
	border-radius: 25px;
	margin: 1
}

.paymentImage.checked {
	box-shadow: 0px 0px 12px 0px #ADD6E5;
}

.paymentImage .form-check {
	padding-right: 0 !important;
	display: flex !important;
	height: 100% !important;
}

.paymentImage .form-check label.form-check-label {
	align-self: center;
}
