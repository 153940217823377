.learning__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 81px 64px;
  gap: 40px;
}
.learning__image img {
  /* padding: 70px !important; */
  /* border: 0 !important; */
  background: white !important;
  transition-delay: 0.5s;
  
  transition-duration: 0.5s;
}
.learning__image img:hover {
  background: #f05165 !important;
  padding: 23px !important;
  border: 0;
}
.learning__container .leaning__box {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.learning__container .leaning__box.closed {
  opacity: 0.5;
  /* pointer-events: none; */
  cursor: not-allowed;
}
.learning__container .leaning__box .learning__image {
  width: 100%;
  align-items: center;
  text-align: center;
  text-align: -webkit-center;
}
.tesst {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px solid #ADD6E5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f1f5;
  object-fit: contain;
}
.learning__container .leaning__box .learning__image img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 3px solid #ADD6E5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f1f5;
  object-fit: cover;
}
.learning__container .leaning__box .leaning__title {
  color: #000000;
  font-size: 25px;
  font-weight: 900;
  line-height: 75px;
}

@media (max-width: 1600px) {
  .learning__container .leaning__box .learning__image {
    width: 250px;
    height: 250px;
  }
  .learning__container .leaning__box .learning__image img {
    /* width: 60%; */
  }
  .learning__container .leaning__box .leaning__title {
    font-size: 19px;
    font-weight: 900;
  }
}
@media (max-width: 1400px) {
  .learning__container {
    grid-template-columns: repeat(4, 1fr);
  }
  .learning__container .leaning__box .learning__image {
    align-items: center;
    text-align: center;
    text-align: -webkit-center;
    /* width: 250px; */
    height: 250px;
  }
  .learning__container .leaning__box .leaning__title {
    font-size: 28px;
  }
}
@media (max-width: 1199px) {
  .learning__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 900px) {
  .learning__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 620px) {
  .learning__container {
    grid-template-columns: repeat(1, 1fr);
  }
  .learning__container .leaning__box .learning__image {
    width: 100%;
    align-items: center;
    text-align: center;
    text-align: -webkit-center;
  }
}
