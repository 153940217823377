.teacher-details-wrap {
	margin-block: 120px 70px;
}
.teacher-details-wrap .MuiTypography-root {
	font-family: "Cairo";
}
.teacher-details-comments-wrap {
	margin-bottom: 70px;
	padding: 16px;
}
