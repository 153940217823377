.main__header {
	padding: 18px 0;
	width: 100%;
	background-color: #fff;
}

.main__header .header__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 35px;
}
.main__header .rigth__nav {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 14px;
}

.main__header .rigth__nav .sociallink {
	cursor: pointer;
	transition: 0.2s;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
}
.main__header .rigth__nav .sociallink path {
	transition: 0.2s;
}

.main__header .rigth__nav .sociallink:hover path {
	fill: white;
}
.main__header .rigth__nav .sociallink.instagram:hover {
	background-color: #e4405f;
}
.main__header .rigth__nav .sociallink.youtube:hover {
	background-color: #ff0000;
}
.main__header .rigth__nav .sociallink.facebook:hover {
	background-color: #1877f2;
}

.main__header .header__parag {
	margin-right: 10px;
	font-size: 14px;
	font-weight: 800;
	color: #000000;
	white-space: pre;
}
.center__nav {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 18px;
}
.center__nav .header__discount__btn {
	padding: 6px 18px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #fb7488;
}
.left__nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 13px;
}

.left__nav .whatsapp_link {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 5px;
	transition: 0.2s;
}
.left__nav .whatsapp_link:hover {
	background-color: #25d366;
	color: white;
}
.left__nav .whatsapp_link p {
	transition: 0.2s;
}
.left__nav .whatsapp_link:hover p {
	color: white;
}
.left__nav .header__number {
	font-weight: 800;
	font-size: 10px;
	color: #000000;
}
.header__bottom {
	margin-top: 40px !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.header__bottom .header__bottom__login {
	background-color: #d9d9d9;
	color: #000000;
	font-size: 21px;
	font-weight: 800;
	width: 230px;
	height: 100px;
	display: block;
	text-align: center;
	cursor: pointer;
}
.header__bottom .header__bottom__center {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-basis: 30%;
}
.header__bottom .header__bottom__center.logged__in {
	flex-basis: 70%;
}
.header__bottom .header__bottom__center .header__links {
	cursor: pointer;
	font-size: 16px;
	font-weight: 700;
	color: #000000;
}
.header__bottom .header__bottom__center .header__link__page {
	display: flex;
	align-items: center;
	column-gap: 10px;
	font-size: 18px;
	font-weight: 700;
	color: #000000;
	cursor: pointer;
}
.header__link__page .bullet {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #ADD6E5;
}
.header__link__page:nth-of-type(2) .bullet {
	background-color: #aa4db9;
}
.header__link__page:nth-of-type(3) .bullet {
	background-color: #f05165;
}
.header__link__page:nth-of-type(4) .bullet {
	background-color: #deb50f;
}
.header__bottom__rigth .header__bottom__btns {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 10px;
}
.header__bottom__btns .subsctibe__btn,
.header__bottom__btns .log__btn {
	font-size: 16px;
	font-weight: 700;
	color: #1f1f1f;
	background-color: #f2f1f5;
	width: 130px;
	height: 44px;
	border-radius: 10px;
}
.header__bottom__btns .subsctibe__btn {
	color: #f3fcff;
	background-color: #ADD6E5;
}
.toggle__men {
	display: none;
}
.rigth__nav_mob,
.left__nav__mob {
	display: none;
}
@media (max-width: 1600px) {
	.header__bottom .header__bottom__login {
		width: 200px;
	}
	.header__bottom .header__bottom__center .header__link__page {
		font-size: 16px;
	}
}
@media (max-width: 1400px) {
	.main__header {
		padding: 12px 0;
	}
	.main__header .header__top {
		padding: 0 20px;
	}
	.center__nav,
	.main__header .rigth__nav {
		column-gap: 10px;
	}
	.main__header .header__parag {
		font-size: 12px;
	}
	.center__nav .header__discount__btn {
		font-size: 10px;
	}
	.header__bottom {
		margin-top: 20px !important;
	}
	.header__bottom .header__bottom__login {
		font-size: 18px;
		/* width: 200px; */
		height: 36px;
	}
	.header__bottom__btns .subsctibe__btn,
	.header__bottom__btns .log__btn {
		font-size: 12px;
		width: 110px;
		height: 36px;
		border-radius: 6px;
	}
	.header__bottom .header__bottom__center .header__link__page {
		font-size: 14px;
	}
	.header__bottom .header__bottom__center.logged__in {
		flex-basis: 60%;
		justify-content: space-around;
	}
	.header__link__page .bullet {
		width: 24px;
		height: 24px;
	}
}
@media (max-width: 1199px) {
	.header__bottom {
		position: relative;
	}
	.header__bottom .header__bottom__center {
		position: absolute;
		top: 100%;
		left: 50%;
		background-color: #fff;
		transform: translateX(-50%);
		flex-direction: column;
		border-radius: 10px;
		align-items: flex-start;
		overflow: hidden;
		z-index: 1000;
		box-shadow: 0px 10px 15px 0px #0000001a;
		transition: 0.3s ease-in-out;
		display: none;
		min-width: 150px;
	}
	.header__bottom .header__bottom__center.open {
		display: flex;
	}
	.header__bottom .header__bottom__center a {
		padding: 10px 20px 10px;
	}
	.header__bottom .header__bottom__center a:first-of-type {
		padding-top: 20px;
	}
	.header__bottom .header__bottom__center a:last-of-type {
		padding-bottom: 20px;
	}
	.toggle__men {
		display: block;
	}
	.toggle__icon {
		font-size: 20px;
	}
}
@media (max-width: 991px) {
	.main__header .header__top {
		flex-wrap: wrap;
		column-gap: 100px;
		row-gap: 10px;
		justify-content: center;
	}
	.center__nav {
		order: 2;
	}
	.left__nav {
		flex: 1;
	}
	.header__bottom {
		margin-top: 10px !important;
	}
}
@media (max-width: 767px) {
	.main__header .header__top {
		column-gap: 20px;
	}
	.main__header .left__nav,
	.main__header .rigth__nav {
		display: none;
	}
	.rigth__nav_mob,
	.left__nav__mob {
		display: flex;
		align-items: center;
		padding: 10px 20px 10px;
		/* flex-direction: column; */
	}
	.left__nav__mob {
		padding-bottom: 20px;
		column-gap: 10px;
	}
	.left__nav__mob .header__number {
		font-weight: 800;
		font-size: 12px;
		color: #000000;
	}
	.header__bottom .header__bottom__center a:last-of-type {
		padding: 10px 20px 10px;
	}
	.main__header .header__parag {
		margin: 0;
	}
	.rigth__nav_mob {
		flex-direction: column-reverse;
		row-gap: 4px;
		align-items: flex-start;
	}
	.rigth__nav_mob a {
		padding: 0 10px !important;
	}
	.rigth__nav_mob > div:not(.header__parag) {
		height: 14px;
	}
}
@media (max-width: 575px) {
	.header__bottom {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.header__bottom .header__bottom__login {
		width: 60px;
	}

	.header__bottom__rigth:last-of-type {
		width: 100%;
	}
	.header__bottom__rigth .header__bottom__btns {
		width: 100%;
	}
	.header__bottom__rigth .header__bottom__btns button {
		flex: 1;
	}
	.center__nav {
		width: 100%;
		flex-wrap: wrap;
		row-gap: 4px;
	}
	.user__header__card .user__details {
		display: none;
	}
	.main__header .rigth__nav {
		flex-direction: column;
		width: 100%;
	}
}
@media (max-width: 380px) {
	.center__nav {
		flex-direction: column-reverse;
	}
	.center__nav .header__discount__btn {
		width: 100%;
		padding: 12px;
		border-radius: 50px;
		font-size: 12px;
	}
}
