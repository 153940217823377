.client-item-review-wrap {
	padding: 16px;
	text-align: center;
}

.client-item-review-wrap .client-img {
	width: 65px;
	height: 65px;
	margin-inline: auto;
}

.client-item-review-wrap .MuiCardContent-root {
	padding: 0;
}
.client-item-review-wrap .client-review {
	font-size: 1.2rem;
	font-style: italic;
	margin-block: 10px;
	font-weight: 500;
}

.client-item-review-wrap .client-review .MuiSvgIcon-root {
	color: var(--primary);
}
.client-item-review-wrap .client-review .MuiSvgIcon-root:last-of-type {
	transform: rotateY(180deg);
}

.client-item-review-wrap .client-name {
	font-family: "Cairo";
}
