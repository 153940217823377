.header__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 35px;
}
.header__top .rigth__nav {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 14px;
}

.header__top .rigth__nav .sociallink {
	cursor: pointer;
	transition: 0.2s;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
}
.header__top .rigth__nav .sociallink path {
	transition: 0.2s;
}

.header__top .rigth__nav .sociallink:hover path {
	fill: white;
}
.header__top .rigth__nav .sociallink.instagram:hover {
	background-color: #e4405f;
}
.header__top .rigth__nav .sociallink.youtube:hover {
	background-color: #ff0000;
}
.header__top .rigth__nav .sociallink.facebook:hover {
	background-color: #1877f2;
}

.header__top .header__parag {
	margin-right: 10px;
	font-size: 14px;
	font-weight: 800;
	color: #000000;
	white-space: pre;
}
.header__top .center__nav {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 18px;
}
.header__top .center__nav .header__discount__btn {
	padding: 6px 18px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #fb7488;
}
.header-top .left__nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 13px;
}

.header-top .left__nav .whatsapp_link {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 5px;
	transition: 0.2s;
}
.header-top .left__nav .whatsapp_link:hover {
	background-color: #25d366;
	color: white;
}
.header-top .left__nav .whatsapp_link p {
	transition: 0.2s;
}
.header-top .left__nav .whatsapp_link:hover p {
	color: white;
}
.header-top .left__nav .header__number {
	font-weight: 800;
	font-size: 10px;
	color: #000000;
}
