.our__programs__section {
  margin: 105px auto;
}
.programs__container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 33px;
  /* max-width: 1560px;
  margin: 0 auto; */
}
.programs__container .program__card {
  padding: 20px 27px 20px 20px;
  display: flex;
  align-items: center;
  column-gap: 28px;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-radius: 20px;
}
.programs__container .program__card .program__icon {
  flex-shrink: 0;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ADD6E5;
}
.programs__container .card:nth-child(2) .program__card  .program__icon{
  background-color: #AA4DB9;
}
.programs__container .card:nth-child(3) .program__card  .program__icon{
  background-color: #F05165;
}
.programs__container .card:nth-child(4) .program__card  .program__icon{
  background-color: #DEB50F;
}
.programs__container .program__card .program__title {
  font-size: 17px;
  font-weight: 800;
  line-height: 32px;
}
.programs__container .program__card .program__text {
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
}

@media(max-width: 1600px) {
  .programs__container {
    column-gap: 14px;
  }
  .programs__container .program__card {
    column-gap: 14px;
    padding: 16px 16px 16px 12px;
  }
  .programs__container .program__card .program__icon {
    width: 60px;
    height: 60px;
  }
  .programs__container .program__card .program__title {
    font-size: 16px;
  }
}
@media(max-width: 1200px) {
  .programs__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .programs__container .program__card {
    padding: 24px;
  }
  .programs__container .program__card .program__icon {
    width: 75px;
    height: 75px;
  }
}
@media(max-width: 767px) {
  .programs__container {
    grid-template-columns: repeat(1, 1fr);

  }
}
@media(max-width: 400px) {
  .programs__container .program__card {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
  .programs__container .program__card .program__icon {
    border-radius: 50%;
  }
}